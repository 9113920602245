import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components";
import DailyBiblePage from "../container/daily-bible";
import { BACKEND_BASE_URL } from "../constant/constants";

const WorshipResourcesPage = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Worship Resource" />
      <DailyBiblePage data={serverData.daily_bible} />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const daily_bible = await fetch(BACKEND_BASE_URL + "base_config");

    if (!daily_bible.ok) {
      throw new Error(`Response failed`);
    }

    return {
      props: {
        daily_bible: await daily_bible.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default WorshipResourcesPage;
