import React from "react";

import { DailyBible } from "../components/daily-bible";

const DailyBiblePage = props => {
  const { data } = props;
  return <DailyBible data={data} />;
};

export default DailyBiblePage;
