import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

import { Breadcrumb } from "./breadcrumb";

const breadcrumbData = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Worship Resources",
  },
];

const DailyBible = props => {
  const { data } = props;

  return (
    <div className="daily-bible">
      <div className="container">
        <div className="mt-5">
          <Breadcrumb data={breadcrumbData} />
        </div>
        <h3 className="daily-bible__title">Worship Resources</h3>
        <span className="daily-bible__sub-title">Daily Bible Reading</span>
        <div className="daily-bible__content">
          <div className="row">
            <div className="col-12 col-md-4">
              <img
                className="daily-bible__image"
                src="https://hm-mkd-public.s3.ap-south-1.amazonaws.com/bible-verses/current"
                alt="dummyBible"
              />
            </div>
            <div className="col-12 col-md-8">
              <StaticImage
                width={90}
                height={73}
                src="../images/quote-mark.png"
                alt="quote-mark-logo"
              />
              <div className="daily-bible__description">
                {data?.bvod?.html ? parse(data?.bvod?.html) : ""}
              </div>
              <span className="daily-bible__module-name">
                -{data?.bvod?.iden}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DailyBible };
